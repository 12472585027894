import dayjsutc from "@dayjsutc";
import { supabase } from "../../../../supabase";
import {
  SupabaseWorksheetDataSchema,
  supabaseWorksheetDataSchema,
} from "./supabaseWorksheetDataSchema/supabaseWorksheetDataSchema";

export const getCurrentMonthStokAlatData = async (
  rekapanToCreateDate: Date
): Promise<SupabaseWorksheetDataSchema> => {
  const nextMonthDate = dayjsutc(rekapanToCreateDate).add(1, "month");

  const startOfNextMonth = nextMonthDate.startOf("month").toISOString();

  console.log({ startOfNextMonth });

  const endOfNextMonth = nextMonthDate.endOf("month").toISOString();

  console.log({ endOfNextMonth });

  const { data, error } = await supabase
    .from("record")
    .select("masuk,keluar,company_name,alat_name,tanggal")
    .gte("tanggal", startOfNextMonth)
    .lte("tanggal", endOfNextMonth);

  if (error) throw error;
  const myData = data?.map((each) => {
    return {
      ...each,
      company_name: {
        name: each.company_name,
      },
      alat_name: {
        name: each.alat_name,
      },
    };
  });
  const parsedData = supabaseWorksheetDataSchema.parse(myData);

  return parsedData;
};
