import { useMutation } from "@tanstack/react-query";
import { Button } from "flowbite-react";
import { useRef, useState } from "react";
import { HiPlus } from "react-icons/hi";
import {
  LoadingSpinner,
  useToastContext,
} from "../../../../context/ToastContext";
import generateAllRekapans from "../../../../helpers/createRekapan/generateAllRekapans/generateAllRekapans";
import { useFirstOneContext } from "../first-one-provider";
import { SupabaseWorksheetDataSchema } from "../getCurrentMonthStokAlatData/supabaseWorksheetDataSchema/supabaseWorksheetDataSchema";
import {
  ClusteredCompanyNames,
  CorrectCompanyName,
  PossibleCorrectCompanyNameFromPrevMonthStokAlat,
} from "./group-company-typo-names/types";
import GroupCompanyTypoNamesModal from "./group-company-typo-names/ui/group-company-typo-names-modal";

const GenerateAllRekapansButton = () => {
  const {
    startRekapanCreationDate,
    endRekapanCreationDate,
    isAnyDateSelectedForStartRekapanCreation,
  } = useFirstOneContext();

  const { showToast } = useToastContext();

  const [promiseResolver, setPromiseResolver] = useState<Function | undefined>(
    undefined
  );

  const [showModal, setShowModal] = useState(false);

  const [clusteredCompanyNames, setClusteredCompanyNames] = useState<
    ClusteredCompanyNames | undefined
  >(undefined);

  const prevMonthCorrectCompanyNames = useRef<
    Record<
      CorrectCompanyName,
      {
        typoCompanyNames: string[];
        correctCompanyName: CorrectCompanyName;
        possibleCorrectCompanyNameFromPrevMonthStokAlat:
          | PossibleCorrectCompanyNameFromPrevMonthStokAlat
          | undefined;
      }
    >
  >();

  console.log(prevMonthCorrectCompanyNames.current);

  const handleModalConfirmation = (
    clusteredCompanyNames: ClusteredCompanyNames
  ) => {
    console.log({ clusteredCompanyNames });
    if (!promiseResolver) throw new Error("No promise resolver");
    setShowModal(false);
    promiseResolver(clusteredCompanyNames);
    setClusteredCompanyNames(clusteredCompanyNames);
    prevMonthCorrectCompanyNames.current = clusteredCompanyNames;
  };

  console.log({ clusteredCompanyNames });

  const [currentMonthStokAlatData, setCurrentMonthStokAlatData] = useState<
    SupabaseWorksheetDataSchema | undefined
  >(undefined);

  const handleWaitForModalConfirmation = (
    currentMonthStokAlatData: SupabaseWorksheetDataSchema
  ): Promise<ClusteredCompanyNames> => {
    return new Promise((resolve, reject) => {
      console.log("Running");
      setShowModal(true);
      console.log({ currentMonthStokAlatData });
      setCurrentMonthStokAlatData(currentMonthStokAlatData);
      console.log("Toggled show modal");

      setPromiseResolver(() => resolve);
    });
  };

  const {
    isPending,
    error,
    mutate: mutateGenerateAllRekapans,
  } = useMutation({
    mutationFn: () =>
      generateAllRekapans(
        startRekapanCreationDate!,
        endRekapanCreationDate!,
        // The idea is the first wait just sets the promise resolver, but the promise resolver
        // will actually resolve with clustered company names
        handleWaitForModalConfirmation
      ),
  });

  const handleGenerateAllRekapans = () => {
    if (!isAnyDateSelectedForStartRekapanCreation) {
      showToast("error", "Please select a date for the rekapan start date");
      return;
    }

    if (
      startRekapanCreationDate === undefined ||
      endRekapanCreationDate === undefined
    ) {
      showToast("error", "Please select a date for the rekapan end date");
      return;
    }

    mutateGenerateAllRekapans();
  };

  console.log({ showModal });

  const renderLoadingSpinner = () => (isPending ? <LoadingSpinner /> : null);

  if (error) throw error;

  return (
    <>
      <Button
        data-testid="generate-all-rekapans-button"
        disabled={isPending}
        color="primary"
        className="flex items-center gap-x-2"
        onClick={handleGenerateAllRekapans}
      >
        <HiPlus className="text-xl" />
        <p>Generate all rekapans</p>
      </Button>

      {renderLoadingSpinner()}

      <button onClick={() => setShowModal(true)}>CLICKTOGGLE</button>

      <GroupCompanyTypoNamesModal
        showModal={showModal}
        handleModalConfirmation={handleModalConfirmation}
        currentMonthStokAlatData={currentMonthStokAlatData}
        prevMonthCorrectCompanyNames={prevMonthCorrectCompanyNames.current}
      />
    </>
  );
};

export default GenerateAllRekapansButton;
