class PreconditionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "PreconditionError";
  }
}

const precondition = (condition: boolean, errorMessage: string): void => {
  if (!condition) throw new PreconditionError(errorMessage);
};

export default precondition;
