import { addMonths } from "date-fns";
import { z } from "zod";
import DateStringEditor, {
  DateStringEditorParams,
} from "../../../../components/DateStringEditor";
import PositiveIntegerEditor from "../../../../components/PositiveIntegerEditor";
import yyyy_mm_dd_formatDate from "../../../../helpers/yyyy_mm_dd_formatDate";
import { tanggalValueFormatter } from "../tanggalValueFormatter/tanggalValueFormatter";

// Put zod in in some later time

type Args = {
  companyNames: string[] | undefined;
  selectedDate: Date;
};

export const createColDefs = (args: Args) => {
  const { companyNames, selectedDate } = args;
  const nextMonthDate = yyyy_mm_dd_formatDate(addMonths(selectedDate, 1));

  console.log({ nextMonthDate });

  const colDefs = [
    {
      field: "tanggal",
      cellDataType: "dateString",
      cellEditor: DateStringEditor,
      cellEditorParams: {
        params: {
          nextMonthDate,
        } as DateStringEditorParams,
      },
      valueFormatter: tanggalValueFormatter,
    },
    {
      headerName: "Company Name",
      field: "company_name",
      cellDataType: "text",
      valueFormatter: (params: { value: unknown }) => {
        if (params.value === null) return params.value;
        const companyName = z.string().parse(params.value);
        if (!companyNames?.includes(companyName)) return "TYPO FOUND";
        return params.value;
      },
    },
    {
      field: "masuk",
      cellDataType: "number",
      cellEditor: PositiveIntegerEditor,
      width: 200,
    },
    {
      field: "keluar",
      cellDataType: "number",
      cellEditor: PositiveIntegerEditor,
    },
  ];

  return colDefs;
};

export const defaultColfDef = {
  editable: true,
};
