import logger from "@logger";
import { AgGridReact } from "ag-grid-react";
import console from "console";
import { addMonths } from "date-fns";
import { Ref, useRef, useState } from "react";
import { LoadingSpinner } from "../../../context/ToastContext";
import { useGetAlatNames } from "../../../hooks/useGetAlatNames";
import NavbarSidebarLayout from "../../../layouts/navbar-sidebar";
import { Row } from "../../../types/globals";
import getEndOfMonths from "../getEndOfMonths";
import Provider from "./first-one-provider";
import MyGrid from "./grid/ag-grid";
import MetadataSidebar from "./metadata-sidebar/metadata-sidebar/metadata-sidebar";
import GenerateAllRekapansButton from "./generate-rekapan/generate-rekapan-button";
import SelectRekapanCreationDates from "./generate-rekapan/group-company-typo-names/select-rekapan-creation-dates";

const FirstOne = () => {
  const gridRef = useRef<AgGridReact<Row>>(null);

  const endOfMonths = getEndOfMonths();

  const [selectedDate, setSelectedDate] = useState<Date>(endOfMonths[0]!);

  const nextMonthDate = addMonths(selectedDate, 1);

  // console.log({ endOfMonths });
  // console.log({ selectedDate });
  // console.log({ nextMonthDate });

  logger.debug(selectedDate);
  logger.debug(nextMonthDate);

  const {
    data: alatNamesData,
    isLoading,
    error,
  } = useGetAlatNames({
    startDate: selectedDate,
    endDate: nextMonthDate,
  });

  // console.log({ selectedDate, nextMonthDate });

  const alatNames = alatNamesData ?? [];

  // console.log({ alatNames });

  const [userSelectedAlatName, setUserSelectedAlatName] = useState<string>();

  const selectedAlatName = userSelectedAlatName ?? alatNames?.[0]!;

  const worksheetName = selectedAlatName ?? "";

  console.log({ isLoading, error });

  console.log({ alatNames });

  console.log({ selectedAlatName, worksheetName });

  if (alatNames) {
    return (
      <NavbarSidebarLayout>
        <Provider>
          {/* <Button
            onClick={() => {
              setTestDate(new Date());
            }}
            >
            Click me
          </Button> */}
          <div className="px-4 pt-6">
            <h1 className="font-bold text-4xl dark:text-white">
              Hi, Welcome Back 👋
            </h1>
            <div className="my-4"></div>
            <div className="flex gap-x-4">
              <div className="">
                <MetadataSidebar
                  alatNamesList={alatNames}
                  selectedAlatName={selectedAlatName}
                  setSelectedAlatName={setUserSelectedAlatName}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  dates={endOfMonths}
                  gridRef={gridRef}
                />
              </div>
              <div className="flex-1">
                <MyGrid
                  worksheetName={worksheetName}
                  selectedDate={selectedDate}
                  ref={gridRef as Ref<AgGridReact<Row>>}
                />
              </div>
            </div>
            <div>
              <SelectRekapanCreationDates selectedDate={selectedDate} />
            </div>
            <div>
              <GenerateAllRekapansButton />
            </div>
            {/* <div>
            <Downloads />
          </div> */}
          </div>
        </Provider>
      </NavbarSidebarLayout>
    );
  }

  if (isLoading) return <LoadingSpinner />;
  if (error) return error.message;
};

export default FirstOne;
