import { distance } from "fastest-levenshtein";
import { clusterSimilarWords } from "../clusterSimilarWords/clusterSimilarWords";

// Function to find the list with the word most similar to the search word
// This code was generated by ChatGPT
export function findMostSimilarList(
  lists: string[][],
  searchWord: string,
  threshold: number
): string[] | undefined {
  let minDistance = Infinity;
  let mostSimilarList: string[] = [];

  lists.forEach((list) => {
    list.forEach((word) => {
      const currentDistance = distance(word, searchWord);
      if (currentDistance < minDistance) {
        minDistance = currentDistance;
        mostSimilarList = list;
      }
    });
  });

  if (minDistance <= threshold) {
    return mostSimilarList;
  } else {
    return undefined;
  }
}

// @ts-ignore
if (import.meta.main) {
  const words = ["hello", "hallo", "hullo", "world", "wurld", "word"];
  const threshold = 2;
  const clusteredWords = clusterSimilarWords(words, threshold);
  console.log("Clustered words:", clusteredWords);

  const searchWord = "helo";
  const mostSimilarList = findMostSimilarList(
    clusteredWords,
    searchWord,
    threshold
  );
  console.log("Most similar list:", mostSimilarList);
}
