import { useToastContext } from "#src/context/ToastContext.js";
import useAddHargaAlat from "#src/hooks/useAddHargaAlat.js";
import useGetHargaAlat from "#src/hooks/useGetHargaAlat/useGetHargaAlat.js";
import { queryClient } from "#src/react-query.js";
import { HargaAlatSchema } from "#src/types/schemas.js";
import { Button, Label, TextInput } from "flowbite-react";
import React, { ReactNode, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CamelKeys, camelKeys } from "string-ts";
import { validateInitialStokValue } from "./validateInitialHargas/validateInitialHargas";

type HargaAlatInputs = {
  hargaBulanan: number | null;
  hargaHarian: number | null;
};

type Props = {
  selectedAlatName: string;
  selectedCompanyName: string;
};

export const HargaAlat = (props: Props) => {
  const { selectedAlatName, selectedCompanyName } = props;

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const [toConfirm, setToConfirm] = useState(false);

  console.log({ selectedAlatName, selectedCompanyName });

  const {
    data: harga,
    isLoading,
    error,
  } = useGetHargaAlat(selectedCompanyName, selectedAlatName);

  console.log({ harga });

  let dataList: HargaAlatSchema | undefined;
  let dataSingle: CamelKeys<HargaAlatSchema[number]> | undefined;

  if (isLoading) {
    dataList = undefined;
  } else if (harga !== undefined) {
    dataList = harga;
    if (dataList !== undefined) {
      validateInitialStokValue({ harga: dataList, selectedAlatName });
      dataSingle = camelKeys(dataList[0]!);
    }
  }

  const { showToast } = useToastContext();

  const mutation = useAddHargaAlat({
    onSuccess: async () => {
      showToast("success", "Harga Alat successfully changed");
    },
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: ["harga"],
      });
    },
  });

  const { isPending, variables, isError: isAddInitialStokError } = mutation;

  const serverInitialStokValues = variables || dataSingle;

  console.log({ serverInitialStokValues });

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<HargaAlatInputs>({
    values: serverInitialStokValues,
  });

  const watchInitialHargaBulanan = watch("hargaBulanan");
  const watchInitialHargaHarian = watch("hargaHarian");

  if (!harga) {
    return null;
  }

  if (harga.length !== 1)
    throw new Error(
      `More than one record found when searching for ${selectedAlatName}`
    );

  const onSubmit: SubmitHandler<HargaAlatInputs> = async (data) => {
    if (data.hargaBulanan === null)
      throw Error("Cannot submit an empty hargaBulanan");
    if (data.hargaHarian === null)
      throw Error("Cannot submit an empty hargaHarian");

    if (!toConfirm) {
      setToConfirm(true);
      return;
    }

    setToConfirm(false);

    mutation.mutate({
      hargaBulanan: data.hargaBulanan,
      hargaHarian: data.hargaHarian,
      alatName: selectedAlatName,
      companyName: selectedCompanyName,
    });

    if (error) throw error;

    reset();
    inputRef.current?.blur();
  };

  const createErrorText = (): ReactNode => {
    if (isAddInitialStokError) {
      return (
        <span className="font-medium">
          Error adding new row, please try again
        </span>
      );
    }

    if (errors.hargaBulanan) {
      return <span className="font-medium">{errors.hargaBulanan.message}</span>;
    }

    if (errors.hargaHarian) {
      return <span className="font-medium">{errors.hargaHarian.message}</span>;
    }

    return null;
  };

  const showSubmitButton = Boolean(
    (watchInitialHargaBulanan &&
      watchInitialHargaBulanan !== serverInitialStokValues?.hargaBulanan) ||
      (watchInitialHargaHarian &&
        watchInitialHargaHarian !== serverInitialStokValues?.hargaHarian)
  );

  console.log({ showSubmitButton });

  const { ref: rhfRefHargaBulanan, ...hargaBulananRest } = register(
    "hargaBulanan",
    {
      required: true,
    }
  );
  const { ref: rhfRefHargaHarian, ...hargaHarianRest } = register(
    "hargaHarian",
    {
      required: true,
    }
  );

  return (
    <form
      className="gap-y-2 flex flex-col"
      onBlur={() => {
        if (!toConfirm) {
          reset();
          return;
        }

        if (toConfirm) {
          reset();
          setToConfirm((prevToConfirm) => !prevToConfirm);
          return;
        }
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Label
        htmlFor="hargaBulanan"
        className="text-sm font-normal text-gray-600 dark:text-gray-400"
      >
        Harga Bulanan
      </Label>
      <TextInput
        {...hargaBulananRest}
        ref={(e) => {
          rhfRefHargaBulanan(e);
          inputRef.current = e;
        }}
        type="number"
        step={"any"}
        id="hargaBulanan"
        name="hargaBulanan"
        className={isPending ? "opacity-50" : undefined}
        placeholder="Enter harga bulanan"
        color={errors.hargaBulanan && "failure"}
        helperText={createErrorText()}
      />
      <Label
        htmlFor="hargaHarian"
        className="text-sm font-normal text-gray-600 dark:text-gray-400"
      >
        Harga Harian
      </Label>
      <TextInput
        {...hargaHarianRest}
        ref={(e) => {
          rhfRefHargaHarian(e);
          inputRef.current = e;
        }}
        type="number"
        step={"any"}
        id="hargaHarian"
        name="hargaHarian"
        className={isPending ? "opacity-50" : undefined}
        placeholder="Enter harga harian"
        color={errors.hargaHarian && "failure"}
        helperText={createErrorText()}
      />
      <div></div>
      {showSubmitButton && (
        <Button
          onMouseDown={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            e.preventDefault()
          }
          type="submit"
        >
          {!toConfirm ? "Input" : "Confirm"}
        </Button>
      )}
    </form>
  );
};
