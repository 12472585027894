import dayjs, { Dayjs } from "dayjs";
import * as A from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";

const dayJSToUTCDate = (date: Dayjs): Date => {
  return new Date(date.toDate().toUTCString());
};

function getEndOfMonths(): Date[] {
  const startDate = dayjs.utc("2023-01-01");
  // console.log({ startDate });
  // console.log(startDate.endOf("month"));
  const endDate = dayjs.utc("2024-08-01");

  const endOfMonths = [];
  let currentDate = startDate;

  while (currentDate.isBefore(endDate)) {
    endOfMonths.push(currentDate.endOf("month"));
    currentDate = currentDate.add(1, "month");
  }

  // console.log({ endOfMonths });

  const toReturn = pipe(endOfMonths, A.map(dayJSToUTCDate));

  // console.log({ toReturn });

  return toReturn;
}

export default getEndOfMonths;
