import { Select } from "flowbite-react";
import { SetStateAction } from "react";

type Props = {
  selectedAlatName: string;
  setSelectedAlatName: React.Dispatch<SetStateAction<string | undefined>>;
  alatNames: string[];
};

const AlatNameDropdown = (props: Props) => {
  const { selectedAlatName, setSelectedAlatName, alatNames } = props;
  return (
    <Select
      value={selectedAlatName}
      id="alat-name-choices"
      name="Alat Name Choices"
      data-testid="alat-name-choices"
      onChange={(e) => setSelectedAlatName(e.target.value)}
    >
      {alatNames.map((alatName) => (
        <option
          data-testid={alatName}
          className="w-full"
          key={alatName}
          value={alatName}
        >
          {alatName}
        </option>
      ))}
    </Select>
  );
};

export default AlatNameDropdown;
