import { useQuery } from "@tanstack/react-query";
import { alatNamesKeys } from "../pages/services/first-one/queries";
import { supabase } from "../supabase";
import { alatNamesSchema } from "../types/schemas";

type Args = {
  startDate: Date;
  endDate: Date;
};

const getAlatNames = async (args: Args): Promise<string[]> => {
  // console.log({ startDate: args.startDate });
  // console.log({
  //   startDate: args.startDate.toUTCString(),
  //   endDate: args.endDate.toUTCString(),
  // });
  const { data, error } = await supabase.rpc(
    "get_unique_alat_names_between_dates",
    {
      start_date: args.startDate.toUTCString(),
      end_date: args.endDate.toUTCString(),
    }
  );

  console.log({ data, error });

  const parsedData = alatNamesSchema.parse(data);
  const alatNames = parsedData.map((each) => each.name);
  if (error) throw error;
  return alatNames;
};

export const useGetAlatNames = (args: Args) => {
  // console.log({ args });

  const result = useQuery({
    queryKey: alatNamesKeys.detail(args.startDate, args.endDate),
    queryFn: () => getAlatNames(args),
    gcTime: 0,
  });

  return result;
};

export default useGetAlatNames;
