import dayjs from "dayjs";
import { Select } from "flowbite-react";
import { SetStateAction } from "react";

type DateForGridProps = {
  dates: Date[];
  selectedDate: Date;
  setSelectedDate: React.Dispatch<SetStateAction<Date>>;
};
export const formatDate = (date: Date) => {
  return dayjs.utc(date).format("D MMMM YYYY");
};

export const DateForGrid = (props: DateForGridProps) => {
  const { dates, selectedDate, setSelectedDate } = props;

  const formattedDates = dates.map((date) => formatDate(date));

  const handleSelectedDateChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    console.log({ date: e.target.value });
    const newSelectedDate = new Date(`${e.target.value} EDT`);
    console.log({ newSelectedDate });
    setSelectedDate(newSelectedDate);
  };

  const formattedSelectedDate = formatDate(selectedDate);

  return (
    <form className="gap-y-2 flex flex-col">
      <h4 className="text-sm font-normal text-gray-600 dark:text-gray-400">
        Date for input
      </h4>
      <Select
        id="date"
        name="date"
        data-testid="date-select"
        value={formattedSelectedDate}
        onChange={handleSelectedDateChange}
      >
        {formattedDates.map((formattedDate) => (
          <option className="w-full" key={formattedDate} value={formattedDate}>
            {formattedDate}
          </option>
        ))}
      </Select>
    </form>
  );
};
