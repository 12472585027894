import console, { assert } from "console";
import { eachMonthOfInterval, endOfMonth, isSameDay } from "date-fns";
import { Workbook } from "exceljs";
import * as O from "fp-ts/Option";
import { RekapanWorkbookObj } from "../../../hooks/useGetRekapanData";
import { ClusteredCompanyNames } from "../../../pages/services/first-one/generate-rekapan/group-company-typo-names/types";
import { getCurrentMonthStokAlatData } from "../../../pages/services/first-one/getCurrentMonthStokAlatData/getCurrentMonthStokAlatData";
import { SupabaseWorksheetDataSchema } from "../../../pages/services/first-one/getCurrentMonthStokAlatData/supabaseWorksheetDataSchema/supabaseWorksheetDataSchema";
import { getPrevMonthRekapanToUse } from "../../../pages/services/first-one/grid/handleGenerateRekapan/getPrevMonthRekapanToUse/getPrevMonthRekapanToUse";
import { uploadRekapanRemotely } from "../../../pages/services/first-one/grid/io/uploadRekapanRemotely";
import { checkParsableToDateDDMMYYYY } from "../../../utils/checkParsableToDateDDMMYYYY/checkParsableToDateDDMMYYYY";
import precondition from "../../../utils/precondition";
import downloadExcelFile from "../../downloadExcelFile";
import yyyy_mm_dd_formatDate from "../../yyyy_mm_dd_formatDate";
import { convertRekapanJSToRekapanWorkbook } from "../convertRekapanJSToRekapanWorkbook/convertRekapanJSToRekapanWorkbook";
import {
  RekapanDate,
  convertRekapansJSToRekapanWorkbook,
} from "../convertRekapansJSToRekapanWorkbook";
import { createRekapanJS } from "../createRekapanJS/createRekapanJS";
import { invertClusteredTypoCompanyNames } from "../invertClusteredTypoCompanyName";
import { RekapanWorkbook } from "../types";
import { standardizeStokAlatWorksheetTypoCompanyNames } from "./standardizeStokAlatWorksheetTypoCompanyNames/standardizeStokAlatWorksheetTypoCompanyNames";

export function getEndOfMonthDatesBetween(startDate: Date, endDate: Date) {
  precondition(startDate <= endDate, "Start date is after end date");

  return eachMonthOfInterval({
    start: startDate,
    end: endDate,
  }).map(endOfMonth);
}

const generateSingleRekapanObj = async (
  rekapanToCreateDate: Date,
  startRekapanCreationDate: Date,
  waitForModalConfirmation: (
    currentMonthStokAlatData: SupabaseWorksheetDataSchema
  ) => Promise<ClusteredCompanyNames>
): Promise<RekapanWorkbook> => {
  console.log({ rekapanToCreateDate, startRekapanCreationDate });
  const isStartRekapanCreationDate = isSameDay(
    rekapanToCreateDate,
    startRekapanCreationDate
  );

  const prevMonthRekapanToUse: O.Option<RekapanWorkbookObj> =
    isStartRekapanCreationDate
      ? O.none
      : O.some(await getPrevMonthRekapanToUse(rekapanToCreateDate));

  const parsedData = await getCurrentMonthStokAlatData(rekapanToCreateDate);

  assert(
    parsedData.every(
      ({ tanggal }) => checkParsableToDateDDMMYYYY(tanggal) === true
    ),
    `All tanggals must be in DD/MM/YYYY format`
  );

  console.log({ parsedData });

  const clusteredTypoCompanyNames = await waitForModalConfirmation(parsedData);

  console.log({ clusteredTypoCompanyNames });

  const standardized = standardizeStokAlatWorksheetTypoCompanyNames(
    invertClusteredTypoCompanyNames(clusteredTypoCompanyNames),
    parsedData
  );

  console.log({ standardized });

  // const filterCheck = standardized.filter((each) => {
  //   return each.company_name.name.startsWith("ACSET ADM");
  // });

  // console.log({ filterCheck });

  console.log({ parsedData });

  console.log(JSON.stringify(standardized, null, 2));

  const rekapan = createRekapanJS(standardized, prevMonthRekapanToUse);

  console.dir({ rekapan }, { depth: 2 });

  return rekapan;
};

const generateAllRekapans = async (
  startRekapanCreationDate: Date,
  endRekapanCreationDate: Date,
  waitForModalConfirmation: (
    currentMonthStokAlatData: SupabaseWorksheetDataSchema
  ) => Promise<ClusteredCompanyNames>
) => {
  console.log({ startRekapanCreationDate, endRekapanCreationDate });
  const datesToCreateRekapanFor = getEndOfMonthDatesBetween(
    startRekapanCreationDate,
    endRekapanCreationDate
  );

  const rekapans: Record<RekapanDate, Workbook> = {};

  const rekapanObjs: Record<RekapanDate, RekapanWorkbook> = {};

  for (const dateToCreateRekapanFor of datesToCreateRekapanFor) {
    const rekapan = await generateSingleRekapanObj(
      dateToCreateRekapanFor,
      startRekapanCreationDate,
      waitForModalConfirmation
    );

    console.dir({ rekapan }, { depth: null });

    rekapanObjs[yyyy_mm_dd_formatDate(dateToCreateRekapanFor)] = rekapan;

    const rekapanWorkbook = convertRekapanJSToRekapanWorkbook(rekapan);
    await uploadRekapanRemotely(rekapanWorkbook, dateToCreateRekapanFor);
  }

  console.dir({ rekapanObjs }, { depth: null });

  const workbook = convertRekapansJSToRekapanWorkbook(rekapanObjs);

  downloadExcelFile(workbook, "rekapans.xlsx");

  return [rekapanObjs, workbook] as const;

  // const entries = objectEntries(rekapans);

  // const bufferEntries: [RekapanDate, ExcelJS.Buffer][] = [];

  // for (const [rekapanDate, workbook] of entries) {
  //   const asBuffer = await workbookToBuffer(workbook);
  //   bufferEntries.push([rekapanDate, asBuffer]);
  // }

  // const asObjectBuffers = objectFromEntries(bufferEntries) as Record<
  //   RekapanDate,
  //   Buffer
  // >;

  // zipFiles(asObjectBuffers, "rekapans");

  // Now I want to put all of it into a ip file and then save
};

export default generateAllRekapans;
