import { SetStateAction, useState } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { useToggle } from "usehooks-ts";
import { LoadingSpinner } from "../../../context/ToastContext";
import useGetAlatNamesForCompany from "../../../hooks/useGetAlatNamesForCompany";
import useGetCompanyNames from "../../../hooks/useGetCompanyNames";
import { AlatNameDropdown } from "./company-name-dropdown/alat-name-dropdown/alat-name-dropdown";
import { HargaAlat } from "./company-name-dropdown/alat-name-dropdown/harga-alat/harga-alat";
import { CompanyNameDropdown } from "./company-name-dropdown/company-name-dropdown";

type _MainProps = {
  selectedAlatName: string | undefined;
  selectedCompanyName: string | undefined;
  companyNames: string[];
  alatNames: string[];
  setUserInputAlatName: React.Dispatch<SetStateAction<string | undefined>>;
  setUserInputCompanyName: React.Dispatch<SetStateAction<string | undefined>>;
};

export const _Main = function (props: _MainProps) {
  const {
    selectedAlatName,
    selectedCompanyName,
    companyNames,
    alatNames,
    setUserInputAlatName,
    setUserInputCompanyName,
  } = props;

  const [sidebarOpen, toggle] = useToggle(true);

  if (!sidebarOpen) {
    return (
      <>
        <div className="mb-4 h-full rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6">
          <div className="mb-4 flex items-center justify-between">
            <button className="cursor-pointer rounded text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline">
              <HiMenuAlt1 onClick={toggle} className="h-6 w-6" />
            </button>
          </div>
          <div className="flow-root">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700 flex-col">
              <li className="py-3 sm:py-4">
                <div className="my-2"></div>
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-between border-t border-gray-200 pt-3 dark:border-gray-700 sm:pt-6"></div>
        </div>
        ;
      </>
    );
  }

  return (
    <div className="mb-4 h-full rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6">
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
          Add Harga
        </h3>
        <div className="mx-3"></div>
        <button className="cursor-pointer rounded text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline">
          <HiMenuAlt1 onClick={toggle} className="h-6 w-6" />
        </button>
      </div>
      <div className="flow-root">
        <ul className="divide-y divide-gray-200 dark:divide-gray-700 flex-col">
          <li></li>
          <li className="py-3 sm:py-4">
            <h4 className="text-sm font-normal text-gray-600 dark:text-gray-400">
              Select your Proyek
            </h4>
            <div className="my-2"></div>
            <div className="">
              <CompanyNameDropdown
                companyNames={companyNames}
                selectedCompanyName={selectedCompanyName}
                setUserInputCompanyName={setUserInputCompanyName}
              />
            </div>
            <div>
              <AlatNameDropdown
                alatNames={alatNames}
                selectedAlatName={selectedAlatName}
                setUserInputAlatName={setUserInputAlatName}
              />
            </div>
          </li>
        </ul>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 pt-3 dark:border-gray-700 sm:pt-6">
        {Boolean(selectedCompanyName && selectedAlatName) && (
          <HargaAlat
            selectedAlatName={selectedAlatName!}
            selectedCompanyName={selectedCompanyName!}
          />
        )}
      </div>
    </div>
  );
};

const Main = () => {
  const {
    data: companyNames,
    isLoading: isGetCompanyNamesLoading,
    error,
  } = useGetCompanyNames();

  const [userInputCompanyName, setUserInputCompanyName] = useState<
    string | undefined
  >(undefined);

  const selectedCompanyName = userInputCompanyName ?? companyNames?.[0];

  const {
    data: alatNames,
    isLoading: isAlatNamesLoading,
    error: alatNamsError,
  } = useGetAlatNamesForCompany(selectedCompanyName);

  const [userInputAlatName, setUserInputAlatName] = useState<
    string | undefined
  >(undefined);

  const selectedAlatName = userInputAlatName ?? alatNames?.[0];

  if (!alatNames || !companyNames) return <LoadingSpinner />;

  return (
    <_Main
      selectedAlatName={selectedAlatName}
      selectedCompanyName={selectedCompanyName}
      companyNames={companyNames}
      alatNames={alatNames}
      setUserInputAlatName={setUserInputAlatName}
      setUserInputCompanyName={setUserInputCompanyName}
    />
  );
};

export default Main;
