import logger from "@logger";
import { distance } from "fastest-levenshtein";
import { findMostSimilarList } from "../findMostSimilarList/findMostSimilarList";

// Function to cluster similar words based on Levenshtein distance
// This code was generated by ChatGPT
export function clusterSimilarWords(
  words: string[],
  threshold: number
): string[][] {
  if (threshold === 0) return words.map((word) => [word]);
  const clusters: string[][] = words.map((word) => [word]);

  let merged = true;
  while (merged) {
    merged = false;
    for (let i = 0; i < clusters.length; i++) {
      for (let j = i + 1; j < clusters.length; j++) {
        // Check if all words in clusters[i] are within the threshold distance of all words in clusters[j]
        if (
          clusters[i]!.every((word1) =>
            clusters[j]!.every((word2) => distance(word1, word2) <= threshold)
          )
        ) {
          clusters[i] = clusters[i]!.concat(clusters[j]!);
          clusters.splice(j, 1);
          merged = true;
          break;
        }
      }
      if (merged) {
        break;
      }
    }
  }

  const clustered = clusters.map((cluster) => Array.from(new Set(cluster)));

  // So just make it so that, if prevClustered contains the same name, then remove it from the cluster
  // and have it as its own separate cluster

  logger.debug(clustered.sort());

  return clustered; // Remove duplicates within each cluster
}

// @ts-ignore
if (import.meta.main) {
  const words = ["hello", "hallo", "hullo", "world", "wurld", "word"];
  const threshold = 2;
  const clusteredWords = clusterSimilarWords(words, threshold);
  console.log("Clustered words:", clusteredWords);

  const searchWord = "helo";
  const mostSimilarList = findMostSimilarList(
    clusteredWords,
    searchWord,
    threshold
  );
  console.log("Most similar list:", mostSimilarList);
}
