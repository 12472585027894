import { AgGridReact } from "ag-grid-react";
import React, { ForwardedRef, SetStateAction } from "react";
import { HiMenuAlt1 } from "react-icons/hi";
import { useToggle } from "usehooks-ts";
import { LoadingSpinner } from "../../../../../context/ToastContext";
import { useGetCompanyNames } from "../../../../../hooks/useGetCompanyNames";
import { Row } from "../../../../../types/globals";
import AddRowButton from "../add-row-button";
import AlatInitialStok from "../alat-initial-stok";
import AlatNameDropdown from "../alat-name-dropdown";
import { DateForGrid } from "../date-for-grid/DateForGrid";
import RegisterCompany from "../register-company";
import RegisteredCompaniesList from "../registered-companies-list";

type MetadataSidebarProps = {
  selectedDate: Date;
  dates: Date[];
  setSelectedDate: React.Dispatch<SetStateAction<Date>>;
  alatNamesList: string[];
  selectedAlatName: string;
  setSelectedAlatName: React.Dispatch<SetStateAction<string | undefined>>;
  gridRef: ForwardedRef<AgGridReact<Row>>;
};

const MetadataSidebar = function (props: MetadataSidebarProps) {
  const {
    selectedAlatName,
    setSelectedAlatName,
    alatNamesList,
    dates,
    selectedDate,
    setSelectedDate,
    gridRef,
  } = props;

  console.log("metadata-sidebar");

  const { data: companyNames, isLoading, error } = useGetCompanyNames();

  console.log({ companyNames, isLoading, error });

  const [sidebarOpen, toggle] = useToggle(true);

  const worksheetName = selectedAlatName;

  if (!sidebarOpen) {
    return (
      <>
        <div className="mb-4 h-full rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6">
          <div className="mb-4 flex items-center justify-between">
            <button className="cursor-pointer rounded text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline">
              <HiMenuAlt1 onClick={toggle} className="h-6 w-6" />
            </button>
          </div>
          <div className="flow-root">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700 flex-col">
              <li className="py-3 sm:py-4">
                <div className="my-2"></div>
              </li>
            </ul>
          </div>
          <div className="flex items-center justify-between border-t border-gray-200 pt-3 dark:border-gray-700 sm:pt-6"></div>
        </div>
        ;
      </>
    );
  }

  if (companyNames) {
    return (
      <div
        data-testid="metadata-sidebar"
        className="mb-4 h-full rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6"
      >
        <div className="mb-4 flex items-center justify-between">
          <h3 className="text-xl font-bold leading-none text-gray-900 dark:text-white">
            Metadata
          </h3>
          <div className="mx-3"></div>
          <button className="cursor-pointer rounded text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline">
            <HiMenuAlt1 onClick={toggle} className="h-6 w-6" />
          </button>
        </div>
        <div className="flow-root">
          <ul className="divide-y divide-gray-200 dark:divide-gray-700 flex-col">
            <li className="py-3 sm:py-4">
              <AddRowButton worksheetName={worksheetName} gridRef={gridRef} />
            </li>
            <li>
              <AlatInitialStok selectedAlatName={selectedAlatName} />
            </li>
            <div className="my-1"></div>
            <li>
              <DateForGrid
                dates={dates}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </li>
            <li className="py-3 sm:py-4">
              <label
                htmlFor="alat-name-choices"
                className="text-sm font-normal text-gray-600 dark:text-gray-400"
              >
                Select your workbook
              </label>
              <div className="my-2"></div>
              <div className="">
                <AlatNameDropdown
                  selectedAlatName={selectedAlatName}
                  alatNames={alatNamesList}
                  setSelectedAlatName={setSelectedAlatName}
                />
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <RegisteredCompaniesList companyNames={companyNames!} />
            </li>
            <li className="py-3 sm:py-4">
              <RegisterCompany />
            </li>
          </ul>
        </div>
        <div className="flex items-center justify-between border-t border-gray-200 pt-3 dark:border-gray-700 sm:pt-6"></div>
      </div>
    );
  }

  if (isLoading) return <LoadingSpinner />;

  if (error) return error.message;
};

export default MetadataSidebar;
