import { Select } from "flowbite-react";
import { SetStateAction } from "react";

type Props = {
  selectedCompanyName: string | undefined;
  setUserInputCompanyName: React.Dispatch<SetStateAction<string | undefined>>;
  companyNames: string[];
};

export const CompanyNameDropdown = (props: Props) => {
  const { selectedCompanyName, setUserInputCompanyName, companyNames } = props;

  if (selectedCompanyName === undefined) return <p>No company selected</p>;

  return (
    <Select
      value={selectedCompanyName}
      id="selected-proyek"
      name="Proyek Choices"
      onChange={(e) => {
        const companyNameSelected = companyNames?.find(
          (proyek) => proyek === e.target.value
        );
        console.log({ companyNameSelected });
        setUserInputCompanyName(companyNameSelected);
      }}
    >
      {companyNames?.map((companyName) => (
        <option className="w-full" key={companyName} value={companyName}>
          {companyName}
        </option>
      ))}
    </Select>
  );
};
