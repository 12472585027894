import { Dayjs } from "dayjs";
import { Select } from "flowbite-react";
import { SetStateAction } from "react";

type DateForGridProps = {
  periods: Dayjs[];
  selectedPeriod: Dayjs;
  setSelectedPeriod: React.Dispatch<SetStateAction<Dayjs>>;
};

export const formatDate = (date: Dayjs) => {
  return date.format("D MMMM YYYY");
};

export const ChoosePeriodDropDown = (props: DateForGridProps) => {
  const { periods, selectedPeriod, setSelectedPeriod } = props;

  const handleSelectedDateChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedIndex = e.target.selectedIndex;
    const newSelectedDate = periods[selectedIndex]!;
    setSelectedPeriod(newSelectedDate);
  };

  console.log({ selectedPeriod });

  return (
    <form className="gap-y-2 flex flex-col">
      <h4 className="text-sm font-normal text-gray-600 dark:text-gray-400">
        Dayjs for input
      </h4>
      <Select
        id="manual-create-tagihan-date-dropdown"
        name="manual-create-tagihan-date-dropdown"
        data-testid="manual-create-tagihan-date-dropdown"
        value={selectedPeriod.valueOf()} // Use timestamp as value
        onChange={handleSelectedDateChange}
      >
        {periods.map((date) => (
          <option key={date.valueOf()} value={date.valueOf()}>
            {formatDate(date)}
          </option>
        ))}
      </Select>
    </form>
  );
};
