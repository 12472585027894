import { FaArrowRight } from "react-icons/fa6";
import { MdError } from "react-icons/md";
import { Link } from "react-router-dom";

const defaultErrorMessage =
  "Something went wrong. Please contact the admin (Brady) for assistance";

type Props = {
  error?: Error;
};

const ErrorScreen = (props: Props) => {
  const { error } = props;

  return (
    <div className="flex items-center min-h-screen px-4 py-12 md:py-24">
      <div className="grid max-w-sm gap-4 mx-auto text-center sm:max-w-md md:gap-6 lg:gap-8 lg:max-w-2xl xl:max-w-3xl">
        <div className="space-y-2">
          <MdError className="h-8 w-8" color="error" />
          <h1 className="text-3xl font-extrabold tracking-tighter">
            Uh oh! Something went wrong.
          </h1>
          <p className="text-gray-500 dark:text-gray-400">
            {error?.message ?? defaultErrorMessage}
          </p>
        </div>
        <div className="space-y-2">
          <Link
            className="inline-flex h-8 items-center rounded-md border border-gray-200 bg-white px-4 text-sm font-medium shadow-sm gap-1 transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950"
            to="/"
          >
            <FaArrowRight className="w-4 h-4" />
            Go back to the home page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
