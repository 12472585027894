import dayjs from "dayjs";
import deepEqual from "deep-equal";
import { Column, Workbook, Worksheet } from "exceljs";
import * as A from "fp-ts/Array";
import * as R from "fp-ts/Record";
import { IO } from "fp-ts/lib/IO";
import { fromCompare } from "fp-ts/lib/Ord";
import { pipe } from "fp-ts/lib/function";
import { objectEntries } from "ts-extras";
import cleanWorksheetName from "../cleanWorksheetName/cleanWorksheetName";
import { AlatName, RekapanWorkbook } from "./types";
import {
  createRecordRow,
  createWorkbook,
  getOrCreateAndAddWorksheet,
  writeCurrentBulanTotalSewaAlatAmount,
  writeHeaderNames,
  writePrevBulanTotalSewaAlatAmount,
  writeRow,
} from "./write-to-excel-fns/write-to-excel-fns";

export type RekapanDate = string & {};

export const convertRekapansJSToRekapanWorkbook = (
  rekapansJS: Record<RekapanDate, RekapanWorkbook>
): Workbook => {
  const writeFirstRekapanToWorkbook = (
    workbook: Workbook,
    rekapanJS: RekapanWorkbook
  ): void => {
    pipe(
      rekapanJS,
      objectEntries,
      A.map(([companyName, rekapanWorksheet]) => {
        const worksheetName = pipe(companyName, cleanWorksheetName);

        const writeRekapanWorksheetToWorkbook: IO<Worksheet> = () => {
          return pipe(
            workbook,
            (wb) => getOrCreateAndAddWorksheet(wb, worksheetName),
            (ws) => writeHeaderNames(rekapanWorksheet.header, ws),
            (ws) =>
              writePrevBulanTotalSewaAlatAmount(rekapanWorksheet.header, ws),
            (ws) => {
              const writeRecordRows = () => {
                pipe(
                  rekapanWorksheet.records,
                  R.map((record) => {
                    const row = createRecordRow(record);
                    writeRow(row, ws);
                  })
                );
                return ws;
              };
              return writeRecordRows();
            },
            (ws) =>
              writeCurrentBulanTotalSewaAlatAmount(rekapanWorksheet.header, ws)
          );
        };

        return writeRekapanWorksheetToWorkbook();
      })
    );
  };

  const writeNonFirstRekapanToWorkbook = (
    workbook: Workbook,
    rekapanJS: RekapanWorkbook
  ): void => {
    const extendHeaderNames = <RekapanHeader extends Record<AlatName, any>>(
      rekapanHeader: RekapanHeader,
      worksheet: Worksheet
    ) => {
      const existingColumns = worksheet.columns ?? [];

      // console.log({ existingColumns });

      const currentWorksheetColumns = pipe(
        rekapanHeader,
        objectEntries,
        A.map(([alatName]) => {
          return {
            header: alatName,
            key: alatName,
            width: 10,
          };
        }),
        A.filter((each) => {
          return existingColumns.some((existingColumn) =>
            deepEqual(each, existingColumn)
          );
        })
      );

      const newColumns = [
        ...currentWorksheetColumns,
        ...existingColumns,
      ] satisfies Partial<Column>[];

      worksheet.columns = newColumns;

      return worksheet;
    };

    pipe(
      rekapanJS,
      objectEntries,
      A.map(([companyName, rekapanWorksheet]) => {
        const worksheetName = pipe(companyName, cleanWorksheetName);

        const writeRekapanWorksheetToWorkbook: IO<Worksheet> = () => {
          return pipe(
            workbook,
            (wb) => getOrCreateAndAddWorksheet(wb, worksheetName),
            (ws) => extendHeaderNames(rekapanWorksheet.header, ws),
            (ws) => {
              const writeRecordRows = () => {
                pipe(
                  rekapanWorksheet.records,
                  R.map((record) => {
                    const row = createRecordRow(record);
                    writeRow(row, ws);
                  })
                );
                return ws;
              };
              return writeRecordRows();
            },
            (ws) =>
              writeCurrentBulanTotalSewaAlatAmount(rekapanWorksheet.header, ws)
          );
        };

        return writeRekapanWorksheetToWorkbook();
      })
    );
  };
  const workbook = createWorkbook();

  const sortByDate = fromCompare(
    (x: [RekapanDate, RekapanWorkbook], y: [RekapanDate, RekapanWorkbook]) => {
      return dayjs(x[0]).isBefore(dayjs(y[0])) ? -1 : 1;
    }
  );

  const sortedRekapansJS = pipe(
    rekapansJS,
    objectEntries,
    A.sort(sortByDate),
    A.map(([_, rekapanWorkbook]) => rekapanWorkbook)
  );

  writeFirstRekapanToWorkbook(workbook, sortedRekapansJS[0]!);

  const sortedNonFirstRekapansJS = sortedRekapansJS.slice(1);

  const writeNonFirstRekapansToWorkbook = () => {
    for (const rekapanJS of sortedNonFirstRekapansJS) {
      writeNonFirstRekapanToWorkbook(workbook, rekapanJS);
    }
  };

  writeNonFirstRekapansToWorkbook();

  return workbook;
};
