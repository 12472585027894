// create a context template for me

import dayjsUtc from "@dayjsutc";
import {
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import useGetRekapanData, {
  RekapanWorksheetObjSchema,
} from "../../../hooks/useGetRekapanData";
import useGetRekapanCompanyNames from "../../../hooks/useGetRekeningCompanyNames";
import getEndOfMonths from "../getEndOfMonths";

type ContextType = {
  companyNamesList: string[];
  selectedCompanyName: string;
  setSelectedCompanyName: React.Dispatch<SetStateAction<string>>;
  // The selected date is like the month it's for e.g. 31 May 2024 will show the June rekapan
  selectedDate: Date;
  // The rekapan period is like June etc.
  periodMonth: number;
  daysInPeriod: number;
  periodYear: number;
  isLoading: boolean;
  setSelectedDate: React.Dispatch<SetStateAction<Date>>;
  dates: Date[];
  worksheetData: RekapanWorksheetObjSchema;
  setFetchRekapanWorksheetDataEnabled: React.Dispatch<SetStateAction<boolean>>;
  setFetchRekapanCompanyNamesEnabled: React.Dispatch<SetStateAction<boolean>>;
};

const RekapanContext = createContext<ContextType>({} as ContextType);

let companyNamesLoaded = false;

export const Provider = ({ children }: { children: React.ReactNode }) => {
  const endOfMonths = getEndOfMonths();

  console.log({ endOfMonths });

  const [userSelectedDate, setSelectedDate] = useState<Date>(endOfMonths[0]!);

  const selectedDate = userSelectedDate;

  const periodDate = dayjsUtc(selectedDate).add(1, "month");

  const periodYear = periodDate.year();

  const periodMonth = periodDate.month();

  const daysInPeriod = periodDate.daysInMonth();

  console.log({ selectedDate });

  const [
    fetchRekapanWorksheetDataEnabled,
    setFetchRekapanWorksheetDataEnabled,
  ] = useState(true);

  const [fetchRekapanCompanyNamesEnabled, setFetchRekapanCompanyNamesEnabled] =
    useState(true);

  const { data: companyNames } = useGetRekapanCompanyNames(
    selectedDate,
    fetchRekapanCompanyNamesEnabled
  );

  useEffect(() => {
    const companyNamesIsNotLoading =
      companyNames !== undefined && companyNames.length > 0;
    if (companyNamesIsNotLoading && !companyNamesLoaded!) {
      companyNamesLoaded = true;

      if (companyNames[0] === undefined) return;
      setSelectedCompanyName(companyNames[0]);
    }
  }, [companyNames]);

  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("");

  console.log({ selectedDate });

  const {
    data: worksheetData,
    isLoading,
    error,
  } = useGetRekapanData(
    selectedCompanyName,
    selectedDate,
    fetchRekapanWorksheetDataEnabled
  );

  console.log({ worksheetData });

  if (error) throw error;

  console.log({ worksheetData });

  useEffect(() => {
    if (worksheetData) {
      setFetchRekapanWorksheetDataEnabled(false);
    }
  }, [worksheetData]);

  // const gridRef = useRef<AgGridReact<Row>>(null);

  if (!worksheetData) return;

  console.log({ isLoading });

  if (isLoading) return;
  return (
    <RekapanContext.Provider
      value={{
        companyNamesList: companyNames ?? [""],
        selectedCompanyName: selectedCompanyName ?? "",
        setSelectedCompanyName,
        selectedDate,
        periodMonth,
        daysInPeriod,
        periodYear,
        isLoading,
        setSelectedDate,
        dates: endOfMonths,
        worksheetData,
        setFetchRekapanCompanyNamesEnabled,
        setFetchRekapanWorksheetDataEnabled,
      }}
    >
      {children}
    </RekapanContext.Provider>
  );
};

export default Provider;

export const useRekapanContext = () => useContext(RekapanContext);
