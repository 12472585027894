import { Select } from "flowbite-react";
import { SetStateAction } from "react";

type Props = {
  selectedAlatName: string;
  setSelectedAlatName: React.Dispatch<SetStateAction<string>>;
  alatNames: string[];
  setUserInputAlatName: React.Dispatch<SetStateAction<string>>;
};

export const AlatNameDropdown = (props: Props) => {
  const { selectedAlatName, alatNames, setUserInputAlatName } = props;

  if (selectedAlatName === undefined) return <p>No alat selected</p>;

  if (alatNames.length === 0) return <p>No alats found</p>;

  return (
    <Select
      value={selectedAlatName}
      id="selected-alat"
      name="Alat Choices"
      onChange={(e) => setUserInputAlatName(e.target.value)}
    >
      {alatNames?.map((alatName) => (
        <option className="w-full" key={alatName} value={alatName}>
          {alatName}
        </option>
      ))}
    </Select>
  );
};
