import { Select } from "flowbite-react";

type RegisteredCompaniesListProps = {
  companyNames: string[];
};

const RegisteredCompaniesList = (props: RegisteredCompaniesListProps) => {
  const { companyNames } = props;

  return (
    <div>
      <h4 className="text-sm font-normal text-gray-600 dark:text-gray-400">
        View registered companies
      </h4>
      <div className="my-2"></div>
      <Select
        onChange={() => {}}
        aria-readonly
        value={"choice"}
        id="cregistered-companies"
        name="Registered Companies"
      >
        <option key={"choice"} value={"View registered companies"}>
          View registered companies
        </option>
        {companyNames?.map((companyName) => (
          <option key={companyName} value={companyName}>
            {companyName}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default RegisteredCompaniesList;